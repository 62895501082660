/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import '../css/app.css';

// // Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
// import $ from 'jquery';

// console.log('Hello Webpack Encore! Edit me in assets/js/app.js');
// console.log('Hello Ibrahim');

// /* Voici le script en question : */
// function displayFamilyLearder()
// {
//     $(document).ready(function() {
//         // On récupère la balise <div> en question qui contient l'attribut « data-prototype » qui nous intéresse.
//         var $container = $('div#family_leader_epouses');
    
//         // On définit un compteur unique pour nommer les champs qu'on va ajouter dynamiquement
//         var index = $container.find(':input').length;
    
//         // On ajoute un nouveau champ à chaque clic sur le lien d'ajout.
//         $('#add_epouse').click(function(e) {
//         addEpouse($container);
    
//         e.preventDefault(); // évite qu'un # apparaisse dans l'URL
//         return false;
//         });
    
//         // On ajoute un premier champ automatiquement s'il n'en existe pas déjà un (cas d'une nouvelle annonce par exemple).
//         if (index == 0) {
//         addEpouse($container);
//         } else {
//         // S'il existe déjà des épouses, on ajoute un lien de suppression pour chacune d'entre elles
//         $container.children('div').each(function() {
//             addDeleteLink($(this));
//         });
//         }
    
//         // La fonction qui ajoute un formulaire CategoryType
//         function addEpouse($container) {
//         // Dans le contenu de l'attribut « data-prototype », on remplace :
//         // - le texte "__name__label__" qu'il contient par le label du champ
//         // - le texte "__name__" qu'il contient par le numéro du champ
//         var template = $container.attr('data-prototype')
//             .replace(/__name__label__/g, 'Epouse n°' + (index+1))
//             .replace(/__name__/g,        index)
//         ;
    
//         // On crée un objet jquery qui contient ce template
//         var $prototype = $(template);
//         displayEpouses(index);
    
//         // On ajoute au prototype un lien pour pouvoir supprimer la Epouse
//         addDeleteLink($prototype);
    
//         // On ajoute le prototype modifié à la fin de la balise <div>
//         // displayEpouses(index);
//         $container.append($prototype);
//         // displayEpouses(index);
    
    
//         // Enfin, on incrémente le compteur pour que le prochain ajout se fasse avec un autre numéro
//         index++;
//         }
    
//         // La fonction qui ajoute un lien de suppression d'une Epouse
//         function addDeleteLink($prototype) {
//         // Création du lien
//         var $deleteLink = $('<a href="#" class="btn btn-danger">Supprimer</a>');
    
//         // Ajout du lien
//         $prototype.append($deleteLink);
    
//         // Ajout du listener sur le clic du lien pour effectivement supprimer la Epouse
//         $deleteLink.click(function(e) {
//             $prototype.remove();
    
//             index--;
    
//             e.preventDefault(); // évite qu'un # apparaisse dans l'URL
//             return false;
//         });
//         }
//     });
// }

// function displayEpouse(index)
// {
//     $(document).ready(function() {
//         var $firstDiv = $('div#census_first_div_id_'+index);
//         var $secondDiv = $('div#census_second_div_id_'+index);
//         var $divNom = $('input#family_leader_epouses_'+index+'_nom').parent();
//         var $divPrenom = $('input#family_leader_epouses_'+index+'_prenom').parent();
//         var $divDateNaiss = $('input#family_leader_epouses_'+index+'_dateNaiss').parent();
//         var $divVilleNaiss = $('input#family_leader_epouses_'+index+'_villeNaiss').parent();
//         var $divPaysNaiss = $('select#family_leader_epouses_'+index+'_paysNaiss').parent();
//         $firstDiv.append($divNom);
//         $firstDiv.append($divPrenom);
//         $firstDiv.append($divDateNaiss);
//         $firstDiv.append($divVilleNaiss);
//         $firstDiv.append($divPaysNaiss);
//         var $divFonction = $('input#family_leader_epouses_'+index+'_fonction').parent();
//         var $divContact = $('input#family_leader_epouses_'+index+'_contact').parent();
//         var $divBaiat = $('input#family_leader_epouses_'+index+'_baiat').parent();
//         var $divNbEnfants = $('input#family_leader_epouses_'+index+'_nbEnfants').parent();
//         var $divM = $('input#family_leader_epouses_'+index+'_m').parent();
//         var $divF = $('input#family_leader_epouses_'+index+'_f').parent();
//         $secondDiv.append($divFonction);
//         $secondDiv.append($divContact);
//         $secondDiv.append($divBaiat);
//         $secondDiv.append($divNbEnfants);
//         var $m_f = $('div#m_f'+index);
//         var $m = $('div#m'+index);
//         var $f = $('div#f'+index);
//         $m.append($divM);
//         $f.append($divF);
//         $secondDiv.append($m_f);
//     });
// }

// function displayEpouses(i)
// {
//     $(document).ready(function() {
//         var $epouses = $('div#family_leader_epouses');
//         var index = $epouses.find(':input').length;
//         // for(let i=0; i < index; i++ )
//         // {
//             var $epouse = $('div#family_leader_epouses_'+i);
//             var $divFlex = $('<div class=d-flex></div>');
//             var $firstDiv = $('<div id=census_first_div_id_'+i+' class=census_first_div></div>');
//             var $secondDiv = $('<div  id=census_second_div_id_'+i+' class=census_second_div></div>');
//             var $m_f = $('<div id=m_f'+i+' class=row></div>');
//             var $m = $('<div id=m'+i+' class=col-sm ></div>');
//             var $f = $('<div id=f'+i+' class=col-sm></div>');
//             $m_f.append($m);
//             $m_f.append($f);
//             $secondDiv.append($m_f);
//             $epouse.append($divFlex);
//             $divFlex.append($firstDiv);
//             $divFlex.append($secondDiv);
//             displayEpouse(i);
//     });
// }

// /**** run *****/

// displayFamilyLearder();